const qs = (s) => {
  return document.querySelector(s)
}


function ready(fn) {
  if (document.readyState === "complete" || document.readyState === "interactive") {
      setTimeout(fn, 1);
  } else {
      document.addEventListener("DOMContentLoaded", fn);
  }
}  


ready(function() {
  homeGlider();
  blogGlider();
});

// stick header scroll 


window.onscroll = function (e) {
  var widgetHeight = document.getElementById('nvp-widget-1').clientHeight,
      docBody   = document.documentElement || document.body.parentNode || document.body,
      hasOffset = window.pageYOffset !== undefined,
      scrollTop;
  
  scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop;

  if (scrollTop >= widgetHeight) {
    header.className = 'page-header bg-black py-1 px-3 text-white fixed';
  } else {
    header.className = 'page-header bg-black py-1 px-3 text-white'; 
  }
}

// header toggle
const menu = qs('.header-fullscreen');
const header = qs('#header');
const toggler = qs('.toggle');

toggler.addEventListener('click', () => {
  menu.classList.toggle('visible');

  if (menu.classList.contains('visible')) {
    if(header.classList.contains('fixed')) {

    } else {
      header.classList.add('fixed');
    }    
  }
})


// mobile menu toggle


function toggleSubmenu(event) {
  var submenu = document.getElementsByClassName("submenu-menu"),
      trigger = event.target,
      selector = "." + trigger.getAttribute("data-trigger"),
      showSubmenu = document.querySelectorAll(selector);

  for (var i = 0; i < submenu.length; i++) {
    submenu[i].style.display = "none";
  }
  for (var j = 0; j < showSubmenu.length; j++) {
    showSubmenu[j].style.display = "block";
  }
}
  var triggerSubmenu = document.querySelectorAll("[data-trigger]");
  for (var i = 0; i < triggerSubmenu.length; i++) {
    triggerSubmenu[i].addEventListener("click", toggleSubmenu);
}


// umpire district select 


const umpireDistrict = qs('#umpireDistrict');

if(document.body.contains(umpireDistrict)){
  var contactUmpire = document.getElementsByClassName("umpire-contact");

  for(var i = 0; i < contactUmpire.length; i++){
    contactUmpire[i].style.display = "none";
  }

  umpireDistrict.onchange = function(){
    for (var i = 0; i < contactUmpire.length; i++) {
      contactUmpire[i].style.display = 'none';
    }
    var hiddenDiv = document.getElementById(this.value);
    hiddenDiv.style.display = (this.value == '') ? 'none':'block';
  };
}

const homeCarousel = qs('.home-glider');

function homeGlider() {
  if (document.body.contains(homeCarousel)){
    $('.home-glider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 6000
    });

    $('.blog-glider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 6000
    });
  }
}


